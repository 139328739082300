import { get } from '@rails/request.js';
import { getQualityScore } from './get_connection_quality_score';
import { runPreflight } from 'twilio-video';
import { testMediaConnectionBitrate } from '@twilio/rtc-diagnostics';

const TIMEOUT = 10000;

async function getTwilioVideoToken(url) {
  const response = await get(url, { responseKind: 'json' });
  if (response.ok) {
    const data = await response.json;
    return data.token;
  }
}

async function getIceServers(url) {
  const response = await get(url, { responseKind: 'json' });
  if (response.ok) {
    const data = await response.json;
    return data.ice_servers;
  }
}

async function runMediaConnectionBitrateTest(urlStunTurnVideo) {
  const ice_servers = await getIceServers(urlStunTurnVideo)
  const mediaConnectionBitrateTest = testMediaConnectionBitrate({
    iceServers: ice_servers
  });

  setTimeout(() => {
    mediaConnectionBitrateTest.stop();
  }, TIMEOUT);

  return new Promise((resolve, reject) => {
    mediaConnectionBitrateTest.on('error', (error) => {
      console.error('Error running Media Bitrate diagnostic', error);
      reject(error);
    });

    mediaConnectionBitrateTest.on('end', (report) => {
      resolve(report);
    });
  })
}

async function runPreflightTest(urlTwilioVideo) {
  const twilio_video_token = await getTwilioVideoToken(urlTwilioVideo)
  const preflightTest = runPreflight(twilio_video_token);

  return new Promise((resolve, reject) => {
    preflightTest.on('failed', (error, report) => {
      console.error('preflight error:', error);
      console.log('Received partial report:', report);
      reject(error);
    });

    preflightTest.on('completed', (report) => {
      resolve(report);
    });
  });
}

export async function runConnectionTest(urlTwilioVideo, urlStunTurnVideo) {
  try {
    const [preflightTestReport, bitrateTestReport] = await Promise.all([runPreflightTest(urlTwilioVideo), runMediaConnectionBitrateTest(urlStunTurnVideo)]);

    return getQualityScore(preflightTestReport, bitrateTestReport);
  } catch (error) {
    console.error('An error occurred:', error);
  }
}

export function connectionQuality(total_quality_score) {
  return {
    '0': 'poor',
    '1': 'suboptimal',
    '2': 'good',
    '3': 'excellent',
  }[total_quality_score];
}
