import BaseDiagnosticsAdapter from './base_diagnostics_adapter'

class TestDiagnosticsAdapter extends BaseDiagnosticsAdapter {
    constructor() {
        super()
        this.activeVideoDevice = null
        this.audioInputDevices = [
            { id: 'fake-microphone-device-id', name: 'fake-microphone' },
            { id: 'fake-microphone-device-id-1', name: 'fake-microphone-1' },
        ]
        this.audioOuputDevices = [
          { id: 'fake-speaker-device-id', name: 'fake-audio' },
          { id: 'fake-speaker-device-id-1', name: 'fake-audio-1' },
        ]
    }

    async startVideoTest(videoElement) {
      const event = new CustomEvent("visioCall:audioDevicesChange")
      window.dispatchEvent(event)

      navigator.mediaDevices.getUserMedia({ video: true })
        .then(stream => {
          videoElement.srcObject = stream
          videoElement.onloadedmetadata = () => {
            videoElement.play()
          }
        })
    }

    async getAvailableOutputAudioDevices() {
      return Promise.resolve(this.audioOuputDevices)
    }

    async getAvailableInputAudioDevices() {
      return Promise.resolve(this.audioInputDevices)
    }

    async getAvailableVideoDevices() {
      const defaultDevice = await navigator.mediaDevices.getUserMedia({ video: true })

      return Promise.resolve([
        { id: defaultDevice.id, name: 'Default Video Device' },
        { id: 'fake-video-device-id', name: 'fake-video' },
      ])
    }


    async setOutputAudioDevice(audioDeviceId) {
      this.outputAudioDeviceId = audioDeviceId
      try {
        await this.audioElement?.setSinkId(this.outputAudioDeviceId)
      } catch(e) {
        // no device foun. Mean last used device is not here so setting to null
        // will falback to defaut audio device
      }
    }

    setInputAudioDevice(audioDeviceId) {
      this.inputAudioDeviceId = audioDeviceId
    }

    async setVideoDevice(_videoDeviceId) {
    }

    async startBandwidthTest() {
    }

    stopVideoStream(_videoElement) {
    }

    async cleanOnDisconnect() {
    }
}

export default TestDiagnosticsAdapter
