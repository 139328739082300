import { EventEmitter } from 'events' // transient from twillio or azure-communication
import localAudioFile from '../../assets/audio/audio-output-test.mp3'

class BaseDiagnosticsAdapter {
    constructor() {
      this.audioElement = document.createElement('audio')
      this.outputAudioDeviceId = null
      this.inputAudioDeviceId = null
    }

    setupVisioProvider() {

    }

    startAudioOutputTest() {
        const eventEmitter = new EventEmitter()
        this.audioElement.src = localAudioFile
        this.audioElement.onended = () => {
            eventEmitter.emit('end')
        }

        this.audioElement.play()

        return eventEmitter
    }

    stopAudioOutputTest() {
        if (this.audioElement) {
            this.audioElement.pause()
            this.audioElement.currentTime = 0
        }
    }

    startMicrophoneTest() {
        const eventEmitter = new EventEmitter()

        let mediaConstraints = { video: false }
        if (this.inputAudioDeviceId === null) {
          mediaConstraints['audio'] = true
        } else {
          mediaConstraints['audio'] = { deviceId: this.inputAudioDeviceId }
        }
        navigator.mediaDevices.getUserMedia(mediaConstraints)
            .then(stream => {
                const audioContext = new AudioContext()

                const source = audioContext.createMediaStreamSource(stream)
                const analyser = audioContext.createAnalyser()
                analyser.fftSize = 512
                const bufferLength = analyser.frequencyBinCount
                const dataArray = new Uint8Array(bufferLength)
                source.connect(analyser)

                this.audioInterval = setInterval(() => {
                    analyser.getByteFrequencyData(dataArray)

                    let sum = 0
                    for (let i = 0; i < bufferLength; i++) {
                        sum += dataArray[i]
                    }
                    let average = sum / bufferLength

                    eventEmitter.emit('volume', average)
                }, 10)
            })

        return eventEmitter
    }

    stopMicrophoneTest() {
      clearInterval(this.audioInterval)
    }

    getAudioLevelPercentage(level) {
        const AUDIO_LEVEL_THRESHOLD = 200
        return (level * 100) / AUDIO_LEVEL_THRESHOLD
    }

    startVideoTest(videoElement) {
        navigator.mediaDevices.getUserMedia({ video: true })
            .then(stream => {
                videoElement.srcObject = stream
                videoElement.onloadedmetadata = () => {
                    videoElement.play()
                }
            })
    }

    stopVideoStream(videoElement) {
        if (videoElement && videoElement.srcObject) {
            const stream = videoElement.srcObject
            stream.getTracks().forEach(track => track.stop())
            videoElement.srcObject = null
        }
    }

    getAvailableVideoDevices() {
      return Promise.resolve([])
    }

    getAvailableInputAudioDevices() {
      return Promise.resolve([])
    }

    async setOutputAudioDevice(audioDeviceId) {
      try {
        await this.audioElement.setSinkId(audioDeviceId)
      } catch(e) {
        // no device found. Mean last used device is not present so setting to null
        // will fallback to defaut audio device
      }
    }

    async setInputAudioDevice(_audioDeviceId) {
    }

    async setVideoDevice(_videoDeviceId) {
    }

    cleanOnDisconnect() {}
}

export default BaseDiagnosticsAdapter
