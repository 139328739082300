import BaseDiagnosticsAdapter from './base_diagnostics_adapter'
import Twilio from '../../utils/twilio/twilio_diagnostic'

class TwilioDiagnosticsAdapter extends BaseDiagnosticsAdapter {
    constructor(urlVideoToken, urlStunTurnToken, overrides = {}) {
        super()
        this.twilio = Twilio(overrides)
        this.urlVideoToken = urlVideoToken
        this.urlStunTurnToken = urlStunTurnToken
    }

    async startBandwidthTest() {
        const testResult = await this.twilio.connectionTest(this.urlVideoToken, this.urlStunTurnToken)
        return this.twilio.connectionQuality(testResult.totalQualityScore)

    }

    cleanOnDisconnect() { }
}

export default TwilioDiagnosticsAdapter